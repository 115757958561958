<template>
  <div class="info">
    <el-dialog title="账户信息" width="500px" :visible.sync="isShow" :close-on-click-modal="false" @close="close" append-to-body>
      <el-form class="form" ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="账户" prop="account">
          <el-input v-model.trim="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="code" prop="code" label="验证码">
          <el-input v-model.trim="form.code" placeholder="短信验证码"></el-input>
          <el-button type="primary" class="code-btn" :disabled="!!seconds || !form.mobile"
            @click="onGetCodeButtonClick">{{ codeBtn }}</el-button>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="form.password" autocomplete="off" placeholder="留空则不修改密码" type="password"
            show-password></el-input>
        </el-form-item>
        <el-form-item label="请重复密码" prop="re_password">
          <el-input v-model="form.re_password" autocomplete="off" placeholder="请重复密码" type="password"
            show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitReset">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "./api"
import { Message, MessageBox } from "element-ui"
import gt from "@/mixins/geetest"
import code from "../../views/login/mixins.js"

export default {
  mixins: [code],
  data () {
    var checkPsd = (rule, value, callback) => {
      if (this.form.re_password !== this.form.re_password) {
        this.$refs.form.validateField("re_password");
      }
      callback();
    }
    var checkRepsd = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    }
    return {
      isShow:false,
      form: {
        account: "",
        mobile: "",
        password: "",
        re_password: "",
        code: "",
      },
      rules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            pattern: /^[a-zA-z0-9]\w{3,15}$/,
            message:
              "账号应为4-16位字符，可含数字、字母、下划线",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: "请输入正确格式的手机号",
            trigger: "blur",
          },
        ],
        password: [
          { validator: checkPsd, trigger: "blur" },
          {
            pattern: /^[a-zA-z0-9]\w{5,19}$/,
            message: "6-20位字符，由数字、字母、下划线组成，且不能以下划线开头",
            trigger: "blur",
          },
        ],
        re_password: [{ validator: checkRepsd, trigger: "blur" }],
      },
      width: '500px',
    }
  },
  methods:{
    onGetCodeButtonClick() {
      console.log("开始发送")
      this.captcha.onReady(function () {
      }).onSuccess(() => {
        let result = this.captcha.getValidate();
        console.log(result);
        if (!result)
          return;
        // 发送验证码
        let { mobile = "" } = this.form;
        if (this.seconds > 0) return;
        clearTimeout(this.timer);
        service.getCode({ phone: mobile, ...result }).then(() => {
          this.till = new Date().getTime() + 60 * 1000;
          this.countDown();
        });
      });
      this.captcha.verify();
    },
    openReset() {
      let promise = new Promise((resolve, reject) => resolve());
      service.getAccountInfo().then((res) => {
        this.form.account = res.account;
        this.form.mobile = res.mobile;
        this.isShow = true;
      });

      promise.then(async () => {
        await this.initGt();
      })

    },
    async submitReset() {
      // 校验
      const validatePass = await this.$refs["form"].validate();
      if (!validatePass) return;

      // 提示确认信息
      const confirmYes = await MessageBox.alert(
        `本操作不可还原，确定您输入的信息输入无误吗？`,
        "警告",
        { showCancelButton: true }
      )
        .then(() => true)
        .catch(() => false);
      if (!confirmYes) return;

      // 保存信息
      service.saveAccountInfo(this.form).then((_) => {
        sessionStorage.clear();
        this.isShow = false;

        Message.success("修改成功, 即将重新登录");
        setTimeout(() => {
          this.$router.replace({ path: "/login" });
        }, 2000);
      });
    },
    //极验
    initGt() {
      return gt.register().then(captcha => {
        this.captcha = captcha;
        return captcha;
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.info{
  z-index: 99999;
}
.form {
  margin: 0 auto;
  text-align: left;
}

.el-form-item {
  margin-bottom: 32px;
}

.code {
  .el-input {
    width: 60%;
    margin-right: 3%;
  }

  &-btn {
    width: 28%;
    min-width: 85px;
    padding: 12px 10px;
  }
}
</style>