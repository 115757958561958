<template>
  <el-menu
    class="menu-nav"
    router
    :default-active="$route.path"
    :unique-opened="true"
    :collapse="isCollapse"
  >
    <template v-for="item in menuList">
      <el-submenu
        v-if="item.children && item.children.length"
        :key="item.index"
        :index="item.index"
      >
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item
          v-for="child in item.children"
          :key="child.index"
          :index="child.path"
         @click="$emit('cancel')">{{ child.name}}</el-menu-item>
      </el-submenu>
      <template v-else>
        <el-menu-item :index="item.path" :key="item.index" @click="$emit('cancel')">
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}<span class="c-red ft-bold ft-sz-14" v-if="item.name == '更新记录' && !isNew"> NEW </span></span>
        </el-menu-item>
      </template>
    </template>
  </el-menu>
</template>

<script>
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    menuList: {
      type: Array,
      default: () => []
    },
    isNew: {
      type: Boolean,
      default: true
    },
  }
};
</script>

<style lang="scss" scoped>
.menu-nav{
  background: #3C39AB;
}
.el-menu{
  background-color: #3C39AB!important;
}

.el-menu {
  border: none;
  overflow: hidden;
  background: #14235e!important;
  background-color: #14235e!important;
  padding-left: 20px;
   .el-menu-item {
    color:#ffffff;
    li{
      padding-left: 20px;
    }
  }
}
.menu-nav:not(.el-menu--collapse) {
  width: 200px;
  text-align: left;
  min-height: 100%;
  padding: 22px 0;
  border-radius: 3px;
}
@media screen and (max-width: 480px) {
  .el-menu {
    width: 100%;
    overflow: hidden;
    .el-menu--inline .el-menu-item {
      padding-left: 50px !important;
    }
  }
  .menu-nav:not(.el-menu--collapse) {
    width: 100%;
    text-align: left;
    min-height: 100%;
    padding: 22px 0;
    border-radius: 3px;
  }
}
.el-icon-s-platform{
   color: #ffffff!important;
    margin-right: 15px!important;
}

.el-icon-s-marketing{
   color: #ffffff!important;
    margin-right: 15px!important;
}

.el-icon-s-custom{
   color: #ffffff!important;
    margin-right: 15px!important;
}

.el-icon-s-check{
   color: #ffffff!important;
    margin-right: 15px!important;
}

.el-menu-item ,.el-submenu{
  ::v-deep .el-submenu__title{
    color: #ffffff;
  }
  ::v-deep .el-submenu__title i{
    color: #ffffff!important;
    margin-right: 15px!important;
  }
  ::v-deep .el-submenu__title:hover{
    background: #14235e!important;
  }
  ::v-deep .el-menu{
     background: #14235e!important;
     padding-left: 20px;
  }

}
.el-menu-item:focus, .el-menu-item:hover{
    color: #2696FD;
    background-color: #14235e;
    // border-radius: 5px;
}

</style>
